import { defineStore } from 'pinia';

export const useHoursStore = defineStore('hours', {
	state: () => ({
		hours: {},
		special: {},
		message: {},
		raw: {},
	}),
	actions: {
		init(data) {
			if (!data) return;
			this.raw = data;
			this.hours = formatHours(data.base);

			this.message = data?.message || {};

			// Handle special hours
			this.handleSpecialHours(data.special);
		},

		handleSpecialHours(data) {
			if (!data) return;

			this.special = data;
		},
	},
	getters: {
		getStatus(state, count = 0) {
			// Can't pass timestamp as an argument
			// But you can return a function with local state scope, and then pass the timestamp
			return (timestamp) => {
				const base = {
					open: true,
					message: 'Open TODAY until 5pm',
				};

				const timestampDateString = new Date(timestamp).toLocaleDateString('en-US', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
					timeZone: 'America/Chicago',
				});

				const special = state.special.find((item) => {
					return item.dateString == timestampDateString;
				});

				// Get day of the week based on timestamp
				const date = new Date(timestamp);
				const day = date.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'America/Chicago' }).toLowerCase();

				if (special) {
					if (special?.centerClosed) {
						base.open = false;
						base.message = 'Closed';
						return getNextOpeningTime(date, state.raw.base, count);
					} else {
						// Get current time
						const currentTime = getCurrentTime(date);

						// Format open and closed hours
						const formattedOpenClosed = formatOpenClosed(special);
						// Check if the center is open
						return handleMessage(base, formattedOpenClosed, currentTime, day, special, state);
					}
				} else {
					// Get hours for the day
					const hours = state.raw.base[day];
					// If the center is closed
					if (hours.centerClosed) {
						base.open = false;
						base.message = 'Closed';

						return getNextOpeningTime(date, state.raw.base, count);
					}

					// Otherwise, check if the center is open
					// Get current time
					const currentTime = getCurrentTime(date);

					// Format open and closed hours
					const formattedOpenClosed = formatOpenClosed(hours);

					// Check if the center is open
					return handleMessage(base, formattedOpenClosed, currentTime, day, hours, state);
				}

				return base;
			};
		},
	},
});

const formatHours = (hours) => {
	// Variables
	const groups = {};
	const finalArr = [];
	let activeGroup = 1;

	const abbreviations = {
		sunday: 'Sun',
		monday: 'Mon',
		tuesday: 'Tue',
		wednesday: 'Wed',
		thursday: 'Thu',
		friday: 'Fri',
		saturday: 'Sat',
	};
	const keys = Object.keys(abbreviations);

	// Helper Functions
	const findIndex = (day) => {
		return keys.indexOf(day);
	};

	const getSortedDaysByAbbreviations = () => {
		return Object.entries(hours).sort((a, b) => {
			const [dayA, hoursA] = a;
			const [dayB, hoursB] = b;

			return findIndex(dayA) - findIndex(dayB);
		});
	};

	const initializeGroup = (groupID, day, hours) => {
		groups[groupID] = {
			hours,
			days: [day],
		};
	};

	const checkHoursEquality = (groupID, hours) => {
		if (hours.centerClosed) {
			return hours.centerClosed == groups[groupID].hours?.centerClosed;
		} else {
			const hoursStr = JSON.stringify({ open: hours.open, closed: hours.closed });
			const groupHours = JSON.stringify({
				open: groups[groupID].hours.open,
				closed: groups[groupID].hours.closed,
			});
			return hoursStr == groupHours;
		}
	};

	const newGroup = (day, hours) => {
		activeGroup++;
		initializeGroup(activeGroup, day, hours);
	};

	const handleGroup = (groupID, day, hours) => {
		const hoursSameAsActiveGroup = checkHoursEquality(activeGroup, hours);
		if (hoursSameAsActiveGroup) {
			groups[activeGroup].days.push(day);
		} else {
			newGroup(day, hours);
		}
	};

	const mergeFirstAndLastGroups = () => {
		const shouldMerge = checkHoursEquality(1, groups[activeGroup].hours);

		if (shouldMerge) {
			groups[1].days = [...groups[activeGroup].days, ...groups[1].days];
			delete groups[activeGroup];
		}
	};

	const formatDisplay = (hour) => {
		const splitTime = hour.split(' ');
		const splitHour = splitTime[0].split(':');
		if (splitHour[1] != '00') return hour;
		else return splitHour[0] + ' ' + splitTime[1];
	};

	const makeObject = () => {
		Object.entries(groups).forEach((group) => {
			const hoursObject = {};
			const entry = group[1];

			const firstDay = abbreviations[entry.days[0]];
			const lastDay = abbreviations[entry.days[entry.days.length - 1]];
			let daysString =
				findIndex(entry.days[0]) - findIndex(entry.days[entry.days.length - 1]) == 0
					? `${firstDay}`
					: `${firstDay} - ${lastDay}`;

			let hoursString = entry?.hours?.centerClosed
				? 'CLOSED'
				: `${formatDisplay(entry.hours.open)} - ${formatDisplay(entry.hours.closed)}`;

			hoursObject['days'] = daysString;
			hoursObject['hours'] = hoursString;
			finalArr.push(hoursObject);
		});
	};

	// Lifecycle
	// Loop through sorted days, and create groups
	getSortedDaysByAbbreviations().forEach((entry, index) => {
		// Get current entry values
		const day = entry[0];
		const hours = entry[1];

		// If first group empty, initialize group
		if (!groups[activeGroup]) {
			initializeGroup(activeGroup, day, hours);
		} else {
			// check if group with same hours already exists
			handleGroup(activeGroup, day, hours);
		}
	});

	// Merge first and last groups if they have the same hours
	mergeFirstAndLastGroups();

	// Create final strings
	makeObject();

	return finalArr;
};

const getHours = (time) => {
	if (!time) return null;
	const am = time.includes('AM') || time.includes('12:00 PM');
	const hourString = time.split(':')[0];
	const hour = parseInt(hourString);
	if (am) return hour;
	else return hour + 12;
};

const getMinutes = (time) => {
	if (!time) return null;
	const minuteString = time.split(':')[1].split(' ')[0];
	return parseInt(minuteString);
};

const checkOpen = (open, hour, minute) => {
	const hourOpen = open.hour <= parseInt(hour);
	const minuteOpen = open.minute <= parseInt(minute);

	return hourOpen && minuteOpen;
};

const checkClose = (closed, hour, minute) => {
	const hourClosed = closed.hour <= parseInt(hour);
	const minuteClosed = closed.minute <= parseInt(minute);

	return hourClosed && minuteClosed;
};

const checkStatus = (hours, hour, minute) => {
	const hasOpened = checkOpen(hours.open, hour, minute);
	const hasClosed = checkClose(hours.closed, hour, minute);
	return [hasOpened, hasClosed].filter(Boolean).length;
	// Status Key:
	// 0: Has not opened yet today
	// 1: Opened today
	// 2: Closed for the day
};

const formatOpenClosed = (hours) => {
	return {
		open: {
			hour: getHours(hours.open),
			minute: getMinutes(hours.open),
		},
		closed: {
			hour: getHours(hours.closed),
			minute: getMinutes(hours.closed),
		},
	};
};

const getCurrentTime = (date) => {
	const hour = date.getHours();
	const minute = date.getMinutes();
	return {
		hour,
		minute,
	};
};

const handleMessage = (base, formattedOpenClosed, currentTime, day, hours, state) => {
	const status = checkStatus(formattedOpenClosed, currentTime.hour, currentTime.minute);
	const statusMessage = {
		0: 'Center opens today at __OPEN__',
		1: 'Open today until __CLOSED__',
		2: 'Center reopens __TOMORROW__ at __OPEN__',
	};

	// Set the status message
	base.message = statusMessage[status];

	if (status == 0) {
		base.open = false;
		base.message = base.message.replace('__OPEN__', hours.open);
	} else if (status == 1) {
		base.open = true;
		base.message = base.message.replace('__CLOSED__', hours.closed);
	} else {
		// Get the next day
		const dayIndex = Object.keys(state.raw.base).indexOf(day);
		let nextDayIndex = dayIndex + 1;
		if (nextDayIndex > 6) nextDayIndex = 0;
		const nextDay = Object.keys(state.raw.base)[nextDayIndex];
		const nextHours = state.raw.base[nextDay];
		base.message = base.message.replace('__OPEN__', nextHours.open);

		const rightNow = new Date();
		const tomorrow = new Date(rightNow.setDate(rightNow.getDate() + 1));
		const tomorrowString = tomorrow.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
		base.message = base.message.replace('__TOMORROW__', tomorrowString);
		base.open = false;
	}

	return base;
};

const getNextOpeningTime = (date, hours, count = 0) => {
	// if count is greater than 7, return base
	const base = {
		open: false,
		message: 'Closed',
	};
	if (count > 7) return base;

	// Get the next day
	const next = new Date(date.setDate(date.getDate() + 1));
	const nextDayString = next.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
	const nextHours = hours[nextDayString];

	// If the center is closed, return the next day
	if (nextHours.centerClosed) {
		return getNextOpeningTime(next, hours, count + 1);
	}

	base.message = `Center reopens ${nextDayString} at ${nextHours.open}`;

	return base;
};
